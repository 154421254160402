import type { FC } from 'react';
import type { IconProps } from '@/types/icons';

export const ArenaLogo: FC<IconProps> = ({
	height = 27,
	width = 160,
	...rest
}) => (
	<svg
		aria-hidden
		fill="none"
		focusable={false}
		height={height}
		viewBox="0 0 160 27"
		width={width}
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<title>The Arena Group Logo</title>
		<path
			d="M130.19 0.0690236V2.54483H125.344V0.0690236H123.979V6.37706H125.344V3.66421H130.19V6.37706H131.555V0.0690236H130.19Z"
			fill="currentColor"
		/>
		<path
			d="M122.399 0.0690387V1.37279H119.133V6.37707H117.768V1.37279H114.502V0.0690387H122.399Z"
			fill="currentColor"
		/>
		<path
			d="M139.948 1.30693V0.0690236H133.135V6.37706H139.948V5.15232H134.38V3.66421H139.573V2.54483H134.38V1.30693H139.948Z"
			fill="currentColor"
		/>
		<path
			d="M114.301 22.1932C114.301 20.4944 115.64 19.1248 117.353 19.1248C118.638 19.1248 119.669 19.915 120.11 21.0475L119.629 21.1397C119.254 20.2179 118.437 19.5989 117.366 19.5989C115.907 19.5989 114.81 20.7578 114.81 22.1932C114.81 23.6287 115.881 24.7876 117.366 24.7876C118.718 24.7876 119.629 23.8131 119.749 22.5093H117.34L117.326 22.0747H120.231V22.2064C120.231 23.8921 119.08 25.2353 117.353 25.2353C115.613 25.2485 114.301 23.8657 114.301 22.1932Z"
			fill="currentColor"
		/>
		<path
			d="M121.302 20.6525H121.784V21.6797C122.065 20.9027 122.801 20.4813 123.792 20.6393V21.1266C122.654 20.8895 121.784 21.5743 121.784 22.9307V25.1958H121.302V20.6525Z"
			fill="currentColor"
		/>
		<path
			d="M124.18 22.9044C124.18 21.6138 125.21 20.5603 126.536 20.5603C127.861 20.5603 128.892 21.627 128.892 22.9044C128.892 24.1818 127.848 25.2485 126.536 25.2485C125.21 25.2617 124.18 24.2081 124.18 22.9044ZM128.436 22.9044C128.436 21.864 127.593 21.008 126.536 21.008C125.478 21.008 124.635 21.8508 124.635 22.9044C124.635 23.9579 125.492 24.8139 126.536 24.8139C127.58 24.8139 128.436 23.9579 128.436 22.9044Z"
			fill="currentColor"
		/>
		<path
			d="M129.855 23.3521V20.6393H130.337V23.1809C130.337 24.2476 131.073 24.8139 131.903 24.8139C132.854 24.8139 133.577 24.0633 133.577 22.9439V20.6393H134.058V25.1827H133.577V24.2081C133.296 24.7612 132.707 25.2485 131.836 25.2485C130.806 25.2748 129.855 24.5769 129.855 23.3521Z"
			fill="currentColor"
		/>
		<path
			d="M135.21 20.6525H135.691V21.6928C136.053 21.0475 136.749 20.5734 137.606 20.5734C138.917 20.5734 139.935 21.6402 139.935 22.9176C139.935 24.195 138.904 25.2617 137.606 25.2617C136.749 25.2617 136.039 24.8007 135.691 24.1423V27H135.21V20.6525ZM139.48 22.9044C139.48 21.864 138.636 20.9949 137.565 20.9949C136.495 20.9949 135.651 21.8509 135.651 22.9044C135.651 23.9579 136.495 24.8139 137.565 24.8139C138.636 24.8271 139.48 23.9974 139.48 22.9044Z"
			fill="currentColor"
		/>
		<path
			d="M129.213 13.449H126.897L130.203 16.1618H132.131L130.203 14.5815C131.087 14.1996 131.716 13.3304 131.716 12.3164C131.716 10.96 130.592 9.85377 129.213 9.85377H124.381V16.1618H125.746V11.197H129.213C129.842 11.197 130.351 11.6975 130.351 12.3164C130.364 12.9354 129.842 13.449 129.213 13.449Z"
			fill="currentColor"
		/>
		<path
			d="M121.877 16.1618H123.51L119.213 9.85373H117.768L113.471 16.1486H115.091L115.8 15.1214H121.141L121.877 16.1618ZM116.603 13.9625L118.477 11.276L120.351 13.9625H116.603Z"
			fill="currentColor"
		/>
		<path
			d="M147.672 9.85377V14.5288L143.067 9.85377H141.461V16.1618H142.826V11.4867L147.417 16.1618H149.024V9.85377H147.672Z"
			fill="currentColor"
		/>
		<path
			d="M158.367 16.1618H160L155.703 9.85377H154.257L149.961 16.1486H151.594L152.303 15.1214H157.644L158.367 16.1618ZM153.106 13.9626L154.98 11.276L156.854 13.9626H153.106Z"
			fill="currentColor"
		/>
		<path
			d="M139.948 11.0785V9.85377H133.135V16.1618H139.948V14.9371H134.38V13.449H139.573V12.3296H134.38V11.0785H139.948Z"
			fill="currentColor"
		/>
		<path
			d="M0 25.0665L14.7151 0H25.286L30.5891 25.0665H22.3009L21.458 20.6471H11.3436L8.88522 25.0665H0ZM14.6097 14.6739H20.4395L18.9645 6.9399L14.6097 14.6739Z"
			fill="currentColor"
		/>
		<path
			d="M31.4492 25.0665L35.9445 0H47.9554C56.0328 0 58.3507 3.90153 58.3507 7.4578C58.3507 11.9118 54.7334 14.9156 50.1328 16.0205L56.2435 25.0665H46.9369L41.7743 16.5038H41.2475L39.7023 25.0665H31.4492ZM46.0589 10.876C48.2714 10.876 49.7816 9.87468 49.7816 8.25192C49.7816 6.97442 48.6929 6.07673 46.7613 6.07673H43.1089L42.2309 10.876H46.0589Z"
			fill="currentColor"
		/>
		<path
			d="M57.4294 25.0665L61.9247 0H82.7154L81.5564 6.45652H68.7378L68.1408 9.80563H77.4475L76.4992 15.1228H67.1926L66.5604 18.5755H79.3439L78.185 25.0665H57.4294Z"
			fill="currentColor"
		/>
		<path
			d="M80.4944 25.0665L84.9896 0H91.8028L100.758 13.9488L103.252 0H111.294L106.834 25.0665H99.9154L91.0302 11.1522L88.5016 25.0665H80.4944Z"
			fill="currentColor"
		/>
	</svg>
);
