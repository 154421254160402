'use client';

import Script from 'next/script';
import type { Identifiers } from '@/types/embeds';
import {
	type FC,
	useState,
	useEffect,
	useContext,
	useCallback,
	createContext,
	type PropsWithChildren,
} from 'react';

interface Scripts {
	[key: string]: {
		dataAttributes?: Record<string, string>;
		loaded: boolean;
		onErrorCallback?: () => void;
		onLoadCallback?: () => void;
		src: string;
	};
}

interface ScriptsContextType {
	loadScript: (
		identifier: string,
		src: string,
		onLoad?: () => void,
		onAlreadyLoaded?: () => void,
		onError?: () => void,
		dataAttributes?: Record<string, string>,
	) => void;
	scripts: Scripts;
}

export interface ScriptProps {
	dataAttributes?: Record<string, string>;
	identifier: Identifiers;
	onAlreadyLoaded?: () => void;
	onLoad?: () => void;
}

export const ScriptsContext = createContext<ScriptsContextType | undefined>(
	undefined,
);

export const ScriptsContextProvider: FC<PropsWithChildren<object>> = ({
	children,
}) => {
	const [scripts, setScripts] = useState<Scripts>({});

	const loadScript = useCallback(
		(
			identifier: string,
			src: string,
			onLoad?: () => void,
			onAlreadyLoaded?: () => void,
			onError?: () => void,
			dataAttributes?: Record<string, string>,
		) => {
			if (scripts[identifier]) {
				if (scripts[identifier].loaded) {
					onAlreadyLoaded?.();
					return;
				}

				// To handle multiple onLoad callbacks we can add it here.
				// scripts[identifier].onLoadCallbacks.push(onLoad);
				return;
			}

			const newScript = {
				dataAttributes,
				loaded: false,
				onErrorCallback: onError,
				onLoadCallback: onLoad,
				src,
			};

			setScripts((prev) => ({
				...prev,
				[identifier]: newScript,
			}));
		},
		[scripts],
	);

	const handleError = useCallback((identifier: string) => {
		setScripts((prev) => {
			const scriptData = prev[identifier];

			if (!scriptData) {
				return prev;
			}

			scriptData.onErrorCallback?.();

			return prev;
		});
	}, []);

	const handleLoad = useCallback((identifier: string) => {
		setScripts((prev) => {
			const scriptData = prev[identifier];

			if (!scriptData) {
				return prev;
			}

			scriptData.onLoadCallback?.();

			return {
				...prev,
				[identifier]: {
					loaded: true,
					src: scriptData.src,
				},
			};
		});
	}, []);

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<ScriptsContext.Provider value={{ loadScript, scripts }}>
			{children}

			{Object.entries(scripts).map(([identifier, { dataAttributes, src }]) => {
				// Create props for data attributes
				const dataProps: Record<string, string> = {};
				if (dataAttributes) {
					Object.entries(dataAttributes).forEach(([key, value]) => {
						dataProps[`data-${key}`] = value;
					});
				}

				// Use strategy="afterInteractive" to avoid hydration issues
				return (
					<Script
						data-identifier={identifier}
						id={identifier}
						key={identifier}
						onError={() => handleError(identifier)}
						onLoad={() => handleLoad(identifier)}
						src={src}
						strategy="afterInteractive"
						{...dataProps}
					/>
				);
			})}
		</ScriptsContext.Provider>
	);
};

export const useScriptsContext = (): ScriptsContextType => {
	const context = useContext(ScriptsContext);

	if (!context) {
		throw new Error(
			'useScriptsContext must be used within a ScriptsContextProvider',
		);
	}

	return context;
};

export const useLoadScript = (
	identifier: string,
	src: string,
	onLoad?: () => void,
	onAlreadyLoaded?: () => void,
	onError?: () => void,
	dataAttributes?: Record<string, string>,
) => {
	const { loadScript } = useScriptsContext();

	useEffect(() => {
		loadScript(
			identifier,
			src,
			onLoad,
			onAlreadyLoaded,
			onError,
			dataAttributes,
		);
	}, [
		dataAttributes,
		identifier,
		loadScript,
		onAlreadyLoaded,
		onError,
		onLoad,
		src,
	]);
};
