import type { FC } from 'react';
import Email from '@themaven-net/raven-assets/Email';
import { Button } from '@/components/raven/ui/Button';
import TikTok from '@themaven-net/raven-assets/TikTok';
import Twitter from '@themaven-net/raven-assets/Twitter';
import Youtube from '@themaven-net/raven-assets/Youtube';
import BlueSky from '@themaven-net/raven-assets/BlueSky';
import Threads from '@themaven-net/raven-assets/Threads';
import Facebook from '@themaven-net/raven-assets/Facebook';
import Linkedin from '@themaven-net/raven-assets/Linkedin';
import Flipboard from '@themaven-net/raven-assets/Flipboard';
import Instagram from '@themaven-net/raven-assets/Instagram';
import Pinterest from '@themaven-net/raven-assets/Pinterest';
import ExternalShare from '@themaven-net/raven-assets/ExternalShare';

interface SocialIconProps {
	ariaLabel?: string;
	network: string;
	url: string;
}

export const IconMap: Record<string, FC> = {
	bluesky: BlueSky,
	email: Email,
	facebook: Facebook,
	flipboard: Flipboard,
	instagram: Instagram,
	linkedin: Linkedin,
	pinterest: Pinterest,
	threads: Threads,
	tiktok: TikTok,
	twitter: Twitter,
	website: ExternalShare,
	youtube: Youtube,
};

export const SocialIcon: FC<SocialIconProps> = ({
	ariaLabel,
	network,
	url,
}) => {
	const NetworkIcon = IconMap[network];

	if (!NetworkIcon) {
		return null;
	}

	const defaultAriaLabel = `Open ${network.charAt(0).toUpperCase() + network.slice(1)} social network in a new tab`;

	return (
		<Button
			href={url}
			label={ariaLabel || defaultAriaLabel}
			rel="noreferrer nofollow"
			target="_blank"
			variation="icon-small"
		>
			<NetworkIcon />
		</Button>
	);
};
