'use client';

import type { FC, ReactNode } from 'react';
import { useLoadScript } from '@/context/ScriptsContext';

type QuoteMediaContainerProps = {
	children: ReactNode;
};

export const QuoteMediaContainer: FC<QuoteMediaContainerProps> = ({
	children,
}) => {
	const config = JSON.parse(process.env.NEXT_PUBLIC_QUOTE_MEDIA_CONFIG || '{}');
	useLoadScript('qmod', config.src, undefined, undefined, undefined, {
		'qmod-env': config.env,
		'qmod-version': config.version,
		'qmod-wmid': config.wmid,
	});

	return <div>{children}</div>;
};
